<template>
  <div class="m-2">
    <v-row class="mt-2">
      <v-col cols="12" lg="7" sm="7" md="7">
        <v-card color="teal darken-1 mb-2" dark>
          <v-card-text>
            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card elevation="4">
          <v-card-title>Form Echocardiography</v-card-title>
          <v-card-text>
            <v-row class="m-1 pt-3 mb-0">
              <v-col
                v-for="(v, i) in master.form_cardio.history_clinical"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <!-- <p>{{ v.title }}</p> -->
                <smart-widget
                  :comp="v.widget"
                  :sync-value="
                    (e) => (data.form_cardio.history_clinical[i] = e)
                  "
                  :value="data.form_cardio.history_clinical[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                ></smart-widget>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="4" md="4" sm="4">
                <p><b>MEASUREMENTS</b></p>
                <v-row class="m-0">
                  <v-col
                    v-for="(v, i) in master.form_cardio.measu"
                    :md="v.col ? v.col : 12"
                    :lg="v.col ? v.col : 12"
                    :sm="v.col ? v.col : 12"
                    cols="12"
                    class="mt-0 mb-1 pt-0 pb-3"
                    :key="i"
                  >
                    <!-- <p>{{ v.title }}</p> -->
                    <smart-widget
                      :comp="v.widget"
                      :sync-value="(e) => (data.form_cardio.measu[i] = e)"
                      :value="data.form_cardio.measu[i]"
                      vclass="c-text-field"
                      :data="v.data"
                      :property="v.property"
                      :label="v.label ? v.label : i.replaceAll('_', ' ')"
                      :rules="[rules.required]"
                    ></smart-widget>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="4">
                <p><b>2 D/M MODE</b></p>
                <v-row class="m-0">
                  <v-col
                    v-for="(v, i) in master.form_cardio.m_model_group_1"
                    :md="v.col ? v.col : 12"
                    :lg="v.col ? v.col : 12"
                    :sm="v.col ? v.col : 12"
                    cols="12"
                    class="mt-0 mb-1 pt-0 pb-3"
                    :key="i"
                  >
                    <!-- <p>{{ v.title }}</p> -->
                    <smart-widget
                      :comp="v.widget"
                      :sync-value="
                        (e) => (data.form_cardio.m_model_group_1[i] = e)
                      "
                      :value="data.form_cardio.m_model_group_1[i]"
                      vclass="c-text-field"
                      :data="v.data"
                      :property="v.property"
                      :label="v.label ? v.label : i.replaceAll('_', ' ')"
                      :rules="[rules.required]"
                    ></smart-widget>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="4">
                <p><b>2 D/M MODE</b></p>
                <v-row class="m-0">
                  <v-col
                    v-for="(v, i) in master.form_cardio.m_model_group_2"
                    :md="v.col ? v.col : 12"
                    :lg="v.col ? v.col : 12"
                    :sm="v.col ? v.col : 12"
                    cols="12"
                    class="mt-0 mb-1 pt-0 pb-3"
                    :key="i"
                  >
                    <!-- <p>{{ v.title }}</p> -->
                    <smart-widget
                      :comp="v.widget"
                      :sync-value="
                        (e) => (data.form_cardio.m_model_group_2[i] = e)
                      "
                      :value="data.form_cardio.m_model_group_2[i]"
                      vclass="c-text-field"
                      :data="v.data"
                      :property="v.property"
                      :label="v.label ? v.label : i.replaceAll('_', ' ')"
                      :rules="[rules.required]"
                    ></smart-widget>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="6">
                <p><b>DESCRIPTION</b></p>
                <v-row class="m-0">
                  <v-col
                    v-for="(v, i) in master.form_cardio.description"
                    :md="v.col ? v.col : 12"
                    :lg="v.col ? v.col : 12"
                    :sm="v.col ? v.col : 12"
                    cols="12"
                    class="mt-0 mb-1 pt-0 pb-3"
                    :key="i"
                  >
                    <!-- <p>{{ v.title }}</p> -->
                    <smart-widget
                      :comp="v.widget"
                      :sync-value="(e) => (data.form_cardio.description[i] = e)"
                      :value="data.form_cardio.description[i]"
                      vclass="c-text-field"
                      :data="v.data"
                      :property="v.property"
                      :label="v.label ? v.label : i.replaceAll('_', ' ')"
                      :rules="[rules.required]"
                    ></smart-widget>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="6">
                <p><b>OTHER</b></p>
                <v-row class="m-0">
                  <v-col
                    v-for="(v, i) in master.form_cardio.other"
                    :md="v.col ? v.col : 12"
                    :lg="v.col ? v.col : 12"
                    :sm="v.col ? v.col : 12"
                    cols="12"
                    class="mt-0 mb-1 pt-0 pb-3"
                    :key="i"
                  >
                    <!-- <p>{{ v.title }}</p> -->
                    <smart-widget
                      :comp="v.widget"
                      :sync-value="(e) => (data.form_cardio.other[i] = e)"
                      :value="data.form_cardio.other[i]"
                      vclass="c-text-field"
                      :data="v.data"
                      :property="v.property"
                      :label="v.label ? v.label : i.replaceAll('_', ' ')"
                      :rules="[rules.required]"
                    ></smart-widget>
                  </v-col>
                  <v-col cols="12" md="12" lg="12" sm="12" v-if="!!regid">
                    <v-btn
                      class="btn btn-block"
                      type="button"
                      color="primary"
                      @click="simpan"
                    >
                      Simpan
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="5" sm="5" md="5">
        <v-card>
          <v-card-title>History Echocardiography</v-card-title>
          <v-card-text>
            <div class="text-center">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" @click="initPermintaanPreviosPage"
                      >Previous</a
                    >
                  </li>
                  <li class="page-item">
                    <a
                      class="page-link"
                      v-html="permintaan_pagination_container"
                    ></a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" @click="initPermintaanNextPage"
                      >Next</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
            <div
              class="list-group mb-1"
              v-for="(itemEcho, index) of listHistory"
              :key="index"
            >
              <a
                href="javascript:void(0)"
                class="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1">{{itemEcho.clinic_diagnose ? itemEcho.clinic_diagnose : '-'}}</h6>
                  <small>{{ itemEcho.echo_tgl | moment('DD MMMM YYYY')}}</small>
                </div>
                <p class="mb-1">
                 {{itemEcho.conclusion}}
                </p>
                <div class=mt-2><button @click="hasilEcho(itemEcho.echo_id)" class="btn btn-outline-danger btn-block btn-sm">Detail</button></div>
                <div class=mt-2><button @click="cetakEcho(itemEcho.echo_id)" class="btn btn-outline-primary btn-block btn-sm">Cetak Echo</button></div>
              </a>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDiaglogEcho"
      max-width="980"
    >
      <v-card>
        <v-card-title class="headline">
          Echocardiography
        </v-card-title>
        <v-card-text>
          <div id="hasil"></div>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import Master from '../../data/echo.js'
// eslint-disable-next-line no-unused-vars
import SmartWidget from '@/components/SmartWidget.vue'
import {
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'
export default {
  name: 'EchoForm',
  components: {
    SmartWidget
  },
  data () {
    return {
      master: Master,
      showDiaglogEcho: false,
      searchecho: '',
      permintaans_page: 1,
      permintaans_per_page: 9,
      regid: this.$route.query.registration_id,
      permintaan_pagination_container: '0-0 of 0',
      permintaans_total: '',
      listHistory: [],
      data: {
        pegawai: '',
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          reg_id: '',
          folio_id: ''
        },
        form_cardio: {
          history_clinical: {
            history: '-'
          },
          measu: {
            aorta: '-',
            left_atrium: '-',
            rv_diasolic: '-',
            ejection_fraction: '-',
            eppss: '-',
            lvot_diameter: '-'
          },
          m_model_group_1: {
            lvvedd: '-',
            lvesd: '-',
            ivsdiastolic: '-',
            lvpw_diastolic: '-',
            tapse: '-',
            rwot: '-'
          },
          m_model_group_2: {
            aortic_sinus: '-',
            asc_aorta: '-',
            aortic_arcus: '-',
            desc_aorta: '-',
            abdominal_aorta: '-',
            rvot_diameter: '-'
          },
          description: {
            demensi: '-',
            kontrakrv: '-',
            lvh: '-',
            kontrak: '-',
            analisa_seg: '-',
            katup: '-',
            aorta: '-',
            mitral: '-',
            tricuspid: '-',
            pulmonal: '-'
          },
          other: {
            thrombus: '-',
            sec: '-',
            other: '-'
          }
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  created () {
    // this.pegawai = JSON.parse(localStorage.getItem('user'))
    // this.employee_id = this.pegawai.employee_id
    if (localStorage.getItem('user')) {
      setTimeout(() => {
        this.pegawai = JSON.parse(localStorage.getItem('user'))
      }, 1000)
    }
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.no_rm)
      this.getDataPasienEcho()
      this.getListEcho()
    }
  },
  methods: {
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id
          this.patient_name = response.data.results.nama_pasien
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getDataPasienEcho () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 +
          'pasien/simpan-echo?reg_id=' +
          this.$route.query.registration_id,
        (resp) => {
          const { con, results } = resp

          if (con) {
            this.data.form_cardio.history_clinical.history = results.clinic_diagnose
            this.data.form_cardio.measu.aorta = results.aorta
            this.data.form_cardio.measu.left_atrium = results.left_atrium
            this.data.form_cardio.measu.rv_diasolic = results.rv_diasolic
            this.data.form_cardio.measu.ejection_fraction = results.ejection_fraction
            this.data.form_cardio.measu.eppss = results.eppss
            this.data.form_cardio.measu.lvot_diameter = results.lvot_diameter

            this.data.form_cardio.m_model_group_1.lvvedd = results.lv_edd
            this.data.form_cardio.m_model_group_1.lvesd = results.lv_esd
            this.data.form_cardio.m_model_group_1.lvvedd = results.ivs_diastolic
            this.data.form_cardio.m_model_group_1.ivsdiastolic = results.lvpw_diastolic
            this.data.form_cardio.m_model_group_1.lvpw_diastolic = results.lvpw_diastolic
            this.data.form_cardio.m_model_group_1.tapse = results.tapse
            this.data.form_cardio.m_model_group_1.rwot = results.rvot

            this.data.form_cardio.m_model_group_2.aortic_sinus = results.aortic_sinus
            this.data.form_cardio.m_model_group_2.asc_aorta = results.asc_aorta
            this.data.form_cardio.m_model_group_2.aortic_arcus = results.aortic_arcus
            this.data.form_cardio.m_model_group_2.desc_aorta = results.desc_aorta
            this.data.form_cardio.m_model_group_2.abdominal_aorta = results.abdominal_aorta
            this.data.form_cardio.m_model_group_2.rvot_diameter = results.rvot_diameter

            this.data.form_cardio.description.demensi = results.d1
            this.data.form_cardio.description.lvh = results.d2
            this.data.form_cardio.description.kontrakrv = results.d3
            this.data.form_cardio.description.kontrak = results.d4
            this.data.form_cardio.description.analisa_seg = results.d5
            this.data.form_cardio.description.aorta = results.d6
            this.data.form_cardio.description.mitral = results.d7
            this.data.form_cardio.description.tricuspid = results.d8
            this.data.form_cardio.description.pulmonal = results.d9
            this.data.form_cardio.other.thrombus = results.thrombus
            this.data.form_cardio.other.sec = results.sec
            this.data.form_cardio.other.other = results.conclusion
          }
        },
        'JSON'
      )
    },
    simpan () {
      // eslint-disable-next-line no-unused-vars

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Membuat Form Echo',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Simpan!',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          const data = {
            identitas: {
              no_rm: this.$route.query.no_rm,
              folio_id: this.$route.query.folio_id,
              employee_id: this.pegawai.employee_id,
              registration_id: this.$route.query.registration_id
            },
            echo: this.data
          }
          // eslint-disable-next-line no-undef
          $.post(
            baseUrlV2 + 'pasien/simpan-echo',
            data,
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                successMsg(msg)
              } else {
                errorMsg(msg + '' + JSON.stringify(results))
              }
            },
            'JSON'
          )
        } else {
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
      // console.log(data)
    },
    hasilEcho (v) {
      console.log(v)
      this.showDiaglogEcho = true
      // eslint-disable-next-line no-undef
      $.ajax({
        url: baseUrlEmr + 'index.php/content-vue/hasil-echo?reg_id=' + v,
        // eslint-disable-next-line no-undef
        type: 'GET',
        success: (result) => {
          // // console.log(result);
          // eslint-disable-next-line no-undef
          $('#hasil').html(result)
          // // eslint-disable-next-line no-undef
          // $('#mymodal2').modal({ show: true })
        }
      })
    },
    cetakEcho (view) {
      window
        .open(
          baseUrlEmr +
          'index.php/laporan-medis/cetak-hasil-echo?reg_id=' + view,
          '_blank'
        )
        .focus()
    },
    getListEcho () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'pasien/get-list-echo',
        {
          rm: this.$route.query.no_rm,
          pg: this.permintaans_page,
          ppg: this.permintaans_per_page,
          pls: this.searchecho
        },
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            const pagination = results.pagination
            this.permintaan_pagination_container = pagination
            this.permintaans_total = results.total_pages
            this.listHistory = results.list
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getDataPasienEcho()
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      console.log(currentPage)
      console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getDataPasienEcho()
    }
  }
}
</script>
