export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 4
    },
    no_rekam_medis: {
      label: 'No.Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 4
    },
    tanggal_lahir: { widget: 'wdatenormal', data: null, col: 4 },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 4
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 8
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'No Folio'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 2
    }

  },
  form_cardio: {
    history_clinical: {
      history: {
        widget: 'wtextarea',
        data: null,
        col: 12,
        label: 'History / Clinical Diagnosis'
      }
    },
    measu: {
      aorta: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'Aorta',
        property: { attrs: { suffix: '20-37 mm', type: 'text' } }
      },
      left_atrium: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'Left Atrium',
        property: { attrs: { suffix: '15-40 mm', type: 'text' } }
      },
      rv_diasolic: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'RV Diasolic',
        property: { attrs: { suffix: '< 30 mm', type: 'text' } }
      },
      ejection_fraction: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'Ejection Fraction',
        property: { attrs: { suffix: '53 - 77 %', type: 'text' } }
      },
      eppss: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'EPPSS',
        property: { attrs: { suffix: '< 10 mm', type: 'text' } }
      },
      lvot_diameter: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'LVOT Diameter',
        property: { attrs: { suffix: 'mm', type: 'text' } }
      }
    },
    m_model_group_1: {
      lvvedd: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'LV EDD',
        property: { attrs: { suffix: '35-52 mm', type: 'text' } }
      },
      lvesd: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'LV ESD',
        property: { attrs: { suffix: '26-36 mm', type: 'text' } }
      },
      ivsdiastolic: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'IVS Diastolic',
        property: { attrs: { suffix: '7-11 mm', type: 'text' } }
      },
      lvpw_diastolic: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'LWPW Diastolic',
        property: { attrs: { suffix: '7-11 mm', type: 'text' } }
      },
      tapse: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'TAPSE',
        property: { attrs: { suffix: '> 15 MM', type: 'text' } }
      },
      rwot: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'RWOT',
        property: { attrs: { suffix: 'mm', type: 'text' } }
      }
    },
    m_model_group_2: {
      aortic_sinus: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'Aortic Sinus',
        property: { attrs: { suffix: 'mm', type: 'text' } }
      },
      asc_aorta: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'ASC Aorta',
        property: { attrs: { suffix: 'mm', type: 'text' } }
      },
      aortic_arcus: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'Aortic Arcus',
        property: { attrs: { suffix: 'mm', type: 'text' } }
      },
      desc_aorta: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'DESC Aorta',
        property: { attrs: { suffix: 'mm', type: 'text' } }
      },
      abdominal_aorta: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'Abdominal Aorta',
        property: { attrs: { suffix: 'mm', type: 'text' } }
      },
      rvot_diameter: {
        widget: 'wtext',
        data: null,
        col: 12,
        label: 'RVOT Diameter',
        property: { attrs: { suffix: 'mm', type: 'text' } }
      }
    },
    description: {
      demensi: {
        label: 'Dimensi Ruang Jantung',
        widget: 'wtext',
        data: null,
        col: 12
      },
      lvh: {
        label: 'LVH',
        widget: 'wtext',
        data: null,
        col: 12
      },
      kontrakrv: {
        label: 'Kontraktilitas LV, EF',
        widget: 'wtext',
        data: null,
        col: 12
      },
      kontrak: {
        label: 'Kontraktilitas RV. TAPSE',
        widget: 'wtext',
        data: null,
        col: 12
      },

      analisa_seg: {
        label: 'Analisa Segmental',
        widget: 'wtext',
        data: null,
        col: 12
      },
      katup: {
        label: 'Katup-Katup',
        widget: 'wtext',
        data: null,
        col: 12
      },
      aorta: {
        label: 'Aorta',
        widget: 'wtext',
        data: null,
        col: 12
      },
      mitral: {
        label: 'Mitral',
        widget: 'wtext',
        data: null,
        col: 12
      },
      tricuspid: {
        label: 'Tricuspid',
        widget: 'wtext',
        data: null,
        col: 12
      },
      pulmonal: {
        label: 'Pulmonal',
        widget: 'wtext',
        data: null,
        col: 12
      }
    },
    other: {
      thrombus: {
        label: 'Thrombus',
        widget: 'wtext',
        data: null,
        col: 6
      },
      sec: {
        label: 'SEC',
        widget: 'wtext',
        data: null,
        col: 6
      },
      other: {
        label: 'CATATAN',
        widget: 'wtextarea',
        data: null,
        col: 12
      }
    }
  }
}
